import {
  Box,
  Image,
  PrimaryButton,
  SubtleButton,
} from '@leagueplatform/genesis-commons';
import React, { useEffect, useState } from 'react';
import modalX from 'assets/close.svg';
import rightArrow from 'assets/walkthrough-slides/walkthrough-right-arrow.png';
import leftArrow from 'assets/walkthrough-slides/walkthrough-left-arrow.png';
import { useIntl } from '@leagueplatform/locales';
import {
  SlideData,
  WalkthroughSlideContent,
} from './walkthrough-slide-content';
import { UserSetupFormComponent } from './user-setup-form.component';

type WalkthroughLayoutProps = {
  slideData: SlideData;
  numberOfSlides: number;
  onCompleteWalkthrough: () => void;
  logo?: string;
  formImage?: string;
  hasBrandSlide?: boolean;
};

export const WalkthroughLayout = ({
  slideData,
  numberOfSlides,
  logo = '',
  formImage = '',
  hasBrandSlide = false,
  onCompleteWalkthrough,
}: WalkthroughLayoutProps) => {
  const { formatMessage } = useIntl();
  const minSlide = hasBrandSlide ? 0 : 1;
  const maxSlide: number = hasBrandSlide ? numberOfSlides - 1 : numberOfSlides;
  const [slideValue, setSlideValue] = useState(minSlide);

  const handleExit = () => {
    onCompleteWalkthrough();
  };

  const getNewSlideValue = (curr: number, adjustValue: number): number => {
    const resultingSlide: number = curr + adjustValue;
    if (resultingSlide < minSlide) {
      return minSlide;
    }
    if (resultingSlide > maxSlide) {
      return maxSlide;
    }
    return resultingSlide;
  };

  const changeSlides = (slideAdjustValue: number = 0) => {
    setSlideValue((prevSlideValue: number) =>
      getNewSlideValue(prevSlideValue, slideAdjustValue),
    );
  };

  const handleExitEntry = () => {
    if (slideValue !== maxSlide) {
      setSlideValue(maxSlide);
    }
  };

  // Add window event listener for key presses
  useEffect(() => {
    const handleWindowKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleExitEntry();
      }
      // avoid global handling of enter presses on the form slide (5)
      if (slideValue !== maxSlide && e.key === 'Enter') {
        e.preventDefault();
        changeSlides(1);
      }
    };
    window.addEventListener('keydown', handleWindowKeyPress);
    // cleanup
    return () => {
      window.removeEventListener('keydown', handleWindowKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideValue]);

  const handleArrowKeyDown = (
    e: React.KeyboardEvent<HTMLImageElement>,
    slideAdjustValue: number,
  ) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      changeSlides(slideAdjustValue);
    }
  };

  const handleExitKeyDown = (e: React.KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      handleExitEntry();
    }
  };

  return (
    <>
      {slideValue === 0 && (
        <Image
          src={modalX}
          alt="close this view"
          cursor="pointer"
          role="button"
          width="20px"
          height="20px"
          position="fixed"
          top="35px"
          right="35px"
          tabIndex="0"
          onClick={handleExitEntry}
          onKeyDown={(e: React.KeyboardEvent<HTMLImageElement>) =>
            handleExitKeyDown(e)
          }
        />
      )}
      {slideValue > 0 && slideValue < maxSlide && (
        <Box position="fixed" top="25px" right="35px">
          <SubtleButton
            tabIndex={0}
            onClick={handleExitEntry}
            onKeyDown={(e: React.KeyboardEvent<HTMLImageElement>) =>
              handleExitKeyDown(e)
            }
          >
            {formatMessage({ id: 'SKIP_WALKTHROUGH_INTRODUCTION' })}
          </SubtleButton>
        </Box>
      )}
      <Box
        position="fixed"
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight="430px"
        top="56%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        <Box flex="1">
          {slideValue !== maxSlide ? (
            <WalkthroughSlideContent
              slideValue={slideValue}
              slideData={slideData}
              logo={logo}
            />
          ) : (
            <UserSetupFormComponent
              formImage={formImage}
              handleExit={handleExit}
            />
          )}
        </Box>
        {/* Button tray */}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={
            !hasBrandSlide && slideValue === minSlide
              ? 'flex-end'
              : 'space-between'
          }
          alignItems="center"
          width={
            slideValue > 0 && slideValue < maxSlide - 1
              ? { _: '260px', tablet: '260px' }
              : { _: '280px' }
          }
          marginTop={slideValue === 0 ? 'five' : 'oneAndHalf'}
        >
          {slideValue > minSlide && (
            <Image
              src={leftArrow}
              alt={formatMessage({ id: 'RETURN_TO_PREVIOUS' })}
              cursor="pointer"
              role="button"
              width="56px"
              height="56px"
              tabIndex="0"
              onClick={() => changeSlides(-1)}
              onKeyDown={(e: React.KeyboardEvent<HTMLImageElement>) =>
                handleArrowKeyDown(e, -1)
              }
            />
          )}
          {slideValue === 0 && (
            <PrimaryButton
              autoFocus
              flex="1"
              onClick={() => changeSlides(1)}
              onKeyDown={(e: React.KeyboardEvent<HTMLImageElement>) =>
                handleArrowKeyDown(e, 1)
              }
            >
              {formatMessage({ id: 'CONTINUE' })}
            </PrimaryButton>
          )}
          {slideValue > 0 && slideValue < maxSlide - 1 && (
            <Image
              src={rightArrow}
              alt={formatMessage({ id: 'ADVANCE_TO_NEXT' })}
              cursor="pointer"
              role="button"
              width="56px"
              height="56px"
              tabIndex="0"
              onClick={() => changeSlides(1)}
              onKeyDown={(e: React.KeyboardEvent<HTMLImageElement>) =>
                handleArrowKeyDown(e, 1)
              }
            />
          )}
          {slideValue === maxSlide - 1 && (
            <PrimaryButton
              autoFocus
              flex="0 1 auto"
              onClick={() => changeSlides(1)}
              onKeyDown={(e: React.KeyboardEvent<HTMLImageElement>) =>
                handleArrowKeyDown(e, 1)
              }
            >
              {formatMessage({ id: 'GET_STARTED' })}
            </PrimaryButton>
          )}
        </Box>
      </Box>
    </>
  );
};
