import { Route, useRouteMatch } from '@leagueplatform/routing';
import { PWPMainPage } from './pwp-main.page';

/**
 * Medicare Advantage Personalized Wellness Plan Routes
 */
export const PWPRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Route path={`${path}`}>
      <PWPMainPage />
    </Route>
  );
};
