import React from 'react';
import {
  BodyOne,
  Caption,
  Flex,
  HeadingOne,
  Image,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import lineBreak from 'assets/walkthrough-slides/line-break.png';
import { useIntl } from '@leagueplatform/locales';

export type SlideData = {
  slideImages: { [index: number]: string };
  slideHeadingContents: { [index: number]: string };
  slideBodyContents: { [index: number]: string };
};
const StyledSpan = genesisStyled.span(
  css({
    fontWeight: 'bold',
  }),
);
const StyledH1 = genesisStyled.h1(
  css({
    fontFamily: '"Poppins", "Outfit", "Helvetica", sans-serif',
    fontWeight: 600,
    lineHeight: '32px',
    fontSize: 23,
    letterSpacing: 0.25,
    paragraphSpacing: 0,
    textCase: 'none',
    textDecoration: 'none',
    marginBottom: 'half',
    textAlign: 'left',
  }),
);
export const WalkthroughSlideContent = ({
  slideValue,
  slideData,
  logo,
}: {
  slideValue: number;
  slideData: SlideData;
  logo: string;
}) => {
  const { formatMessage } = useIntl();

  return (
    <Flex flexDirection="column" width="330px">
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Image
          src={slideData.slideImages[slideValue]}
          alt=""
          width="280px"
          height="auto"
          marginBottom="two"
        />
        {slideValue === 0 && (
          <Image
            src={lineBreak}
            alt=""
            width="56px"
            height="auto"
            marginBottom="twoAndHalf"
          />
        )}
      </Flex>
      {slideValue === 0 ? (
        <HeadingOne marginBottom="three" textAlign="center">
          {slideData.slideHeadingContents[slideValue]}
        </HeadingOne>
      ) : (
        <StyledH1>{slideData.slideHeadingContents[slideValue]}</StyledH1>
      )}

      {slideData.slideBodyContents[slideValue] && slideValue !== 0 && (
        <ul>
          {slideData.slideBodyContents[slideValue]
            .split('||')
            .map((item: string) => (
              <li key={item}>
                <BodyOne>
                  <StyledSpan>{item.split('|')[0]}</StyledSpan>:{' '}
                  {item.split('|')[1]}
                </BodyOne>
              </li>
            ))}
        </ul>
      )}
      {slideValue === 0 && (
        <BodyOne textAlign="left">
          {slideData.slideBodyContents[slideValue]}
        </BodyOne>
      )}
      {slideValue === 0 && (
        <>
          <Caption
            color="onSurface.text.subdued"
            marginTop="twoAndHalf"
            marginBottom="half"
          >
            {formatMessage({ id: 'PROVIDED_BY_WITH_COLON' })}
          </Caption>
          {logo && (
            <Image
              src={logo}
              alt="Beneficity Logo"
              width="100px"
              height="auto"
            />
          )}
        </>
      )}
    </Flex>
  );
};
