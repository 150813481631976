import { MASONRY } from '@highmark-web/highmark-common';
import { ActivityStackWidgetV2 } from '@leagueplatform/health-journey';
import { MasonryWSDriverNodeRenderersRegistry } from '@leagueplatform/masonry';

export const initAppMasonryRegistry = () => {
  MasonryWSDriverNodeRenderersRegistry.overrideRenderer(
    MASONRY.MODULE_KEY_HEALTH_JOURNEY,
    'activity_stack',
    ActivityStackWidgetV2,
  );
};
